// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("jquery");
global.bootstrap = require("bootstrap");
global.toastr = require("toastr")

// images
require.context('../images', true)

// fonts
require.context('../fonts', true)

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "styles/application.scss"
import "src/extension.js"
import "src/tabs.js"
import "src/tooltips.js"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "controllers"

import ahoy from "ahoy.js";

ahoy.configure({
  visitsUrl: `/hide1ahoy/visits`,
  eventsUrl: `/hide1ahoy/events`
})

ahoy.trackAll();
