import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["invoiceTypeOption", "billingCycleSection", "invoicePONumberSection", "recipientSection"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const isOrgType = this.currentInvoiceType === "organization"
    const isSubscriptionType = this.currentInvoiceType === "user_subscription"

    if (this.hasBillingCycleSectionTarget) {
      this.billingCycleSectionTarget.style.display = isSubscriptionType ? "none" : "block"
    }

    if (this.hasRecipientSectionTarget) {
      this.recipientSectionTarget.style.display = isOrgType ? "block" : "none"
    }

    if (this.hasInvoicePONumberSectionTarget) {
      this.invoicePONumberSectionTarget.style.display = isOrgType ? "block" : "none"
    }
  }

  get currentInvoiceType() {
    return this.invoiceTypeOptionTargets.find((target) => target.checked).value
  }
}
