import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.modal = new bootstrap.Modal(this.modalTarget, {
      keyboard: false
    })
  }

  show(e) {
    e?.preventDefault()

    this.modal.show()
  }

  hide(e) {
    e?.preventDefault()

    this.modal.hide()
  }
}
