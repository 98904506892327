import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["errors"]

  onSuccess(event) {
    const [data, status, xhr] = event.detail
    this.errorsTarget.innerText = ""
    Turbolinks.visit(window.location)
  }

  onError(event) {
    const [data, status, xhr] = event.detail
    this.errorsTarget.innerHTML = xhr.response
  }
}
