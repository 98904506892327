import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["roleOption", "permittedAdminGroupsSection"]

  connect() {
    this.updateVisibility()
  }

  updateVisibility() {
    const isGroupAdmin = this.currentRole === "group_admin"

    if (this.hasPermittedAdminGroupsSectionTarget) {
      this.permittedAdminGroupsSectionTarget.style.display = isGroupAdmin ? "block" : "none"
    }
  }

  get currentRole() {
    return this.roleOptionTargets.find((target) => target.checked).value
  }
}
