import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = ["userDropdown"]

  updateOrganization(event) {
    fetch(this.url(event.target.value)).then((resp) => resp.json()).then((users) => {
      this.userDropdownTarget.innerHTML = ""
      for (let user of users) {
        this.appendUser(user)
      }
    })
  }

  appendUser(user) {
    const option = document.createElement("option")
    option.text = user.display_email
    option.value = user.id
    this.userDropdownTarget.add(option)
  }

  url(orgId) {
    return this.urlValue.replace('ORG_ID', orgId)
  }
}
