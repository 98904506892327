// Link bootstrap tabs to URL anchors (eg. /path/to/page#tab-name).
// Remember: anchors are only visible in the frontend, so we can't use a backend solution for this.
document.addEventListener("turbolinks:load", () => {
  // Automatically choose the right tab based on the anchor
  const hash = location.hash.replace(/^#/, "")
  if (hash) {
    const tabEl = document.querySelector(`button[data-bs-toggle="tab"][data-bs-target="#${hash}"]`)

    if (tabEl) {
      const tab = new global.bootstrap.Tab(tabEl)
      tab.show()
    } else {
      console.warn(`Unable to find tab #${hash}`)
    }
  }

  // Update the anchor when navigating between tabs.
  // This means you'll stay on the tab if you reload the page.
  const tabs = document.querySelectorAll(`button[data-bs-toggle="tab"]`)
  for (const tabEl of tabs) {
    tabEl.addEventListener("shown.bs.tab", (event) => {
      // Note: Using pushState instead of window.location.hash prevents the page from jumping to the element
      history.pushState({}, "", event.target.dataset.bsTarget)
    })
  }
})
