const EXIT_ID = process.env.NICKL_EXT_ID

const getDKTokenForUser = () => {
  return fetch("/user/generate-dk-token", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => {
      return res
    })
    .catch(err => {
      console.log("get token error: ", err)
      return null
    })
}

const getLiteShareAndCredential = (liteShareId) => {
  return fetch(`/lite_share/ext-data?id=${liteShareId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(res => {
      return res
    })
    .catch(err => {
      console.log("get liteshare launch error: ", err)
      return null
    })
}

const getExtensionVersion = () => {
  return new Promise((resolve) => {
    chrome.runtime.sendMessage(EXIT_ID, {versionCheck: true},
      (response) => {
        resolve(response)
      });
  });
}

const sendAuthDataToExtension = (authData) => {
  return new Promise((resolve, reject) => {
    chrome.runtime.sendMessage(EXIT_ID, {authData},
      (response) => {
        response ? resolve(response) : reject()
      });
  })
}

const sendLiteShareToExtension = (liteShareData) => {
  chrome.runtime.sendMessage(EXIT_ID, {liteShare: liteShareData},
    (response) => {
      window.close()
    });
}

const redirectToLiteGetExtensionPage = () => {
  window.location.replace("/lite/get_extension")
}

const redirectToTrialGetExtensionPage = () => {
  window.location.replace("/trial/get_extension")
}

const redirectToGetExtensionPage = () => {
  window.location.replace("/get_extension")
}

const redirectToSSODeepLink = (authData) => {
  window.location = `nicklpass://auth/saml?access_token=${authData.access_token}&refresh_token=${authData.refresh_token}`
}

const logCurrentUserIntoExtension = () => {
  return new Promise((resolve, reject) => {
    getDKTokenForUser().then(authData => {
      if (authData) {
        document.body.dataset.token = authData.access_token;
        sendAuthDataToExtension(authData).then(res => {
          resolve(res)
        })
      } else {
        reject()
      }
    })
    .catch(err => {
      reject(err)
    })
  })
}

const confirmExtensionCommunication = () => {
  return new Promise((resolve, reject) => {
    if (window.chrome?.runtime) {
      getExtensionVersion().then(version => {
        console.log("got ext vers: ", version)
        version ? resolve(true) : reject(false)
      })
    } else {
      reject(false)
    }
  })
}

const runTrialConfirmProcess = () => {
  // Hide until we know extension is installed...
  $("#trial-confirm").hide()

  confirmExtensionCommunication()
    .then((res) => {
      $("#trial-confirm").show()
      logCurrentUserIntoExtension()
    })
    .catch((err) => {
      redirectToTrialGetExtensionPage()
    })
}

const runLiteShareLaunchProcess = () => {
  // Hide until we know extension is installed...
  $("#lite-share-launch").hide()
  const liteShareId = $("#lite-share-launch").data("lite-share-id")

  confirmExtensionCommunication()
    .then((res) => {
      $("#lite-share-launch").show()
      getLiteShareAndCredential(liteShareId)
        .then(resData => {
          return sendLiteShareToExtension(resData)
        })
        .catch((err) => {
          $("#lite-share-launch > .error").removeClass("hidden")
        })
    })
    .catch((err) => {
      redirectToLiteGetExtensionPage()
    })
}

const ssoExtensionLoginProcess = () => {
  return confirmExtensionCommunication()
    .then((res) => {
      console.log("Confirmed communication: ", res)
      return logCurrentUserIntoExtension()
    })
    .catch(() => {
      redirectToGetExtensionPage()
    })
}

const ssoMobileLoginProcess = () => {
  getDKTokenForUser().then(authData => {
    if (authData) {
      redirectToSSODeepLink(authData)
    } else {
      $(".error").removeClass("hidden")
    }
  })
}

$(document).ready(() => {
  // Trial confirmation page
  if ($("#trial-confirm").length > 0) {
    runTrialConfirmProcess()
  }

  // Lite Share launch page
  if ($("#lite-share-launch").length > 0) {
    runLiteShareLaunchProcess()
  }

  // SSO Extension login page
  if ($("#all-extension-login").length > 0) {
    ssoExtensionLoginProcess().then(() => {
      let firstName = $('body').data('currentUserFirstName');
      let lastName = $('body').data('currentUserLastName');
      let email = $('body').data('currentUserEmail');
      let isFreeTrial = $('body').data('isFreeTrial');
      let token = $('body').data('token');
      if (isFreeTrial && !!token) {
        window.location.href = `https://selfservice.nicklpass.com/testlog?first_name=${firstName}&last_name=${lastName}&email=${email}&token=${token}`;
      }
    }).catch((error) => {
      console.error("Error in ssoExtensionLoginProcess:", error);
    });
  }

  // SSO Mobile login page
  if ($("#sso-mobile-callback").length > 0) {
    ssoMobileLoginProcess()
  }
});
