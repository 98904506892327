/**
 * Thin wrapper around flatpickr datetime picker
 * https://flatpickr.js.org/
 */
import Flatpickr from 'stimulus-flatpickr'

export default class extends Flatpickr {
  connect() {
    this.config = {
      altInput: true,
    }

    super.connect()
  }
}
