import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["left", "stretch", "right"]

  connect() {
    this.setVisibility('hidden') // avoid flicker
    // the first time it runs, the image positions may not have settled yet
    setTimeout(this.stretch.bind(this), 200)
    setTimeout(this.stretch.bind(this), 500)
    window.addEventListener('resize', this.stretch.bind(this));
  }

  stretch() {
    const leftPos = this.leftTarget.getBoundingClientRect().right
    const rightPos = this.rightTarget.getBoundingClientRect().left

    this.stretchTarget.style.left = `${leftPos}px`
    this.stretchTarget.style.width = `${rightPos - leftPos}px`

    this.setVisibility('visible')
  }

  setVisibility(visibility) {
    this.leftTarget.style.visibility = visibility
    this.stretchTarget.style.visibility = visibility
    this.rightTarget.style.visibility = visibility
  }
}
