/* eslint-disable func-names */
/**
 * Thin wrapper around Choices.js
 * https://github.com/jshjohnson/Choices
 */
import { Controller } from 'stimulus'
import * as Choices from 'choices.js/public/assets/scripts/choices'

function getLabelDetails(choice) {
  const [name, rxpId] = choice.label.split(':')
  const imgStr = rxpId
    ? `<img class="max-h-select-option me-2" src="/bill_pay/merchant_logos/${rxpId}.png" onerror="this.parentNode.removeChild(this)" />`
    : ''

  return {
    name,
    imgStr,
  }
}

export default class extends Controller {
  // static targets = [ ]

  connect() {
    this.element[this.identifier] = this // reference the stim controller https://leastbad.com/stimulus-power-move

    this.element.choices = new Choices(this.element, {
      callbackOnCreateTemplates: function (template) {
        return {
          ...Choices.defaults.templates,
          item: (classNames, data) => {
            const { name, imgStr } = getLabelDetails(data)

            return template(`
              <div class="flex items-center ${classNames.item} ${
              data.highlighted ? classNames.highlightedState : classNames.itemSelectable
            } ${data.placeholder ? classNames.placeholder : ''}" data-item data-id="${data.id}" data-value="${data.value}" ${
              data.active ? 'aria-selected="true"' : ''
            } ${data.disabled ? 'aria-disabled="true"' : ''}>
                ${imgStr}
                <span>${name}</span>
              </div>
            `)
          },
          choice: (classNames, data) => {
            const { name, imgStr } = getLabelDetails(data)

            return template(`
              <div class="flex items-center ${classNames.item} ${classNames.itemChoice} ${
              data.disabled ? classNames.itemDisabled : classNames.itemSelectable
            }" data-select-text="${this.config.itemSelectText}" data-choice ${
              data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'
            } data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'}>
                ${imgStr}
                <span>${name}</span>
              </div>
            `)
          },
        }
      },
    })
  }

  // dunno why this causes problems but this causes the page to just spin
  // disconnect() {
  //   this.element.choices.destroy()
  // }
}
