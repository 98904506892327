import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["configFields"]

  change(e) {
    if (e.target.value === "sso_saml") {
      this.configFieldsTarget.classList.remove("hidden")
    } else {
      this.configFieldsTarget.classList.add("hidden")
    }
  }

}
