import { Controller } from "stimulus"
import { csrfToken } from "src/helpers"

export default class extends Controller {
  static targets = ["deleteButton", "checkbox"]
  static values = { deleteUrl: String }

  connect() {
    this.updateState()
  }

  delete(e) {
    e.preventDefault()

    fetch(this.deleteUrlValue, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        authenticity_token: csrfToken(),
        user_ids: this.selectedUserIds
      })
    }).then((response) => Turbolinks.visit(window.location));
  }

  updateState() {
    if (this.selectedUserIds.length > 0) {
      this.deleteButtonTarget.classList.remove('disabled')
    } else {
      this.deleteButtonTarget.classList.add('disabled')
    }
  }

  get selectedUserIds() {
    return this.checkboxTargets.filter((i) => i.checked).map((i) => i.value)
  }
}
