import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["publisher", "publisherPriceRow", "publisherPriceInput", "publisherTotal", "publisherGrandTotal", "userCount", "minimumUsers", "publisherBasePassGrandTotal", "publisherBasePassGrandTotal", "minimumBasepassUsers"]

  connect() {
    for (const publisher of this.publisherTargets) {
      let priceRow = this.priceRow(publisher.dataset.publisherKey)
      $(priceRow).toggle(publisher.checked)
    }
    this.updateBasePassPriceForGroup()
    this.updateTotals()
  }

  updatePubVisibility(e) {
    const target = this.priceRow(e.target.dataset.publisherKey)
    $(target).toggle(e.target.checked)
    this.updateTotals()
  }

  priceRow(publisherKey) {
    return this.publisherPriceRowTargets.find((target) => target.dataset.publisherKey === publisherKey)
  }

  updateTotals() {
    if (!this.hasPublisherTotalTarget) { return }

    let totalPerUser = 0
    for (const target of this.publisherPriceRowTargets) {
      if ($(target).is(':visible')) {
        totalPerUser += this.getPrice(target)
      }
    }

    const userCount = parseInt(this.userCountTarget.innerText)
    const minimumUserCount = parseInt(this.minimumUsersTarget.value)
    const grandTotal = Math.max(userCount, minimumUserCount) * totalPerUser

    this.publisherTotalTarget.innerHTML = this.formatMoney(totalPerUser)
    this.publisherGrandTotalTarget.innerHTML = this.formatMoney(grandTotal)
  }

  getPrice(rowTarget) {
    const inputTarget = this.publisherPriceInputTargets.find((inputTarget) => rowTarget.contains(inputTarget))
    return parseFloat(inputTarget?.value?.replace(/,/g, '') || 0)
  }

  formatMoney(value) {
    return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()
  }

  updateBasePassPriceForOrg(){
    if (!this.hasPublisherBasePassGrandTotalTarget) { return }
    let totalPerUser = 0
    let selectedPublishers = [];
    const hiddenField = document.getElementById('selected_basepass_publishers');
    for (const target of this.publisherPriceRowTargets) {
      if (target.querySelector('input[type="checkbox"][name="included_in_basepass"]').checked) {
        totalPerUser += this.getPrice(target)
        selectedPublishers.push(target.dataset.publisherKey)
      }
    }
    
    hiddenField.value = JSON.stringify(selectedPublishers)
    const minimumBasepassUsers = parseInt(this.minimumBasepassUsersTarget.value)
    const grandTotal = minimumBasepassUsers * totalPerUser

    this.publisherBasePassGrandTotalTarget.innerHTML = this.formatMoney(grandTotal)
  }

  updateBasePassPriceForGroup(){
    if (!this.hasPublisherBasePassGrandTotalTarget) { return }

    let totalPerUser = 0
    for (const target of this.publisherPriceRowTargets) {
      if ($(target).is(':visible')) {
        totalPerUser += this.getPrice(target)
      }
    }
    
    const minimumBasepassUsers = parseInt(this.minimumBasepassUsersTarget.value)
    const grandTotal = minimumBasepassUsers * totalPerUser

    this.publisherBasePassGrandTotalTarget.innerHTML = this.formatMoney(grandTotal)
  }
}
