import { Controller } from "stimulus"

const POLL_FREQUENCY = 500

export default class extends Controller {
  static values = {
    event: String,
    redirect: String
  }

  connect() {
    if (chrome.runtime) {
      setInterval(this.pollEvent.bind(this), POLL_FREQUENCY)
    }
  }

  pollEvent() {
    chrome.runtime.sendMessage(
      process.env.NICKL_EXT_ID,
      {
        type: 'getEvent',
        eventName: this.eventValue
      },
      (response) => {
        if (response) {
          Turbolinks.visit(this.redirectValue)
        }
      }
    );
  }
}
